<template>
  <div class="crypto-panel">
    <h2>Cryptography Panel</h2>

    <!-- RSA Key Pair Generation -->
    <div class="section">
      <h3>Generate RSA Key Pair</h3>
      <button @click="generateRSAKeyPair">Generate RSA Key Pair</button>
      <div v-if="rsaKeyPair">
        <h4>Private Key:</h4>
        <textarea v-model="rsaKeyPair.privateKey" readonly rows="6"></textarea>
        <h4>Public Key:</h4>
        <textarea v-model="rsaKeyPair.publicKey" readonly rows="6"></textarea>
      </div>
    </div>

    <!-- Encrypt and Decrypt Messages -->
    <div class="section">
      <h3>Encrypt and Decrypt</h3>
      <textarea v-model="message" placeholder="Enter message to encrypt"></textarea>
      <button @click="encryptMessage">Encrypt</button>
      <textarea v-model="encryptedMessage" placeholder="Encrypted message" readonly></textarea>
      <button @click="decryptMessage">Decrypt</button>
      <textarea v-model="decryptedMessage" placeholder="Decrypted message" readonly></textarea>
    </div>

    <!-- Sign and Verify -->
    <div class="section">
      <h3>Sign and Verify</h3>
      <textarea v-model="messageToSign" placeholder="Enter message to sign"></textarea>
      <button @click="signMessage">Sign</button>
      <textarea v-model="signature" placeholder="Signature" readonly></textarea>
      <button @click="verifySignature">Verify</button>
      <div v-if="isSignatureValid !== null">
        <span v-if="isSignatureValid">Signature is valid</span>
        <span v-else>Signature is invalid</span>
      </div>
    </div>

    <!-- Certificate Viewer -->
    <div class="section">
      <h3>Upload and View Certificate</h3>
      <input type="file" @change="handleCertificateUpload" />
      <div v-if="certificateDetails">
        <h4>Certificate Details:</h4>
        <pre>{{ certificateDetails }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rsaKeyPair: null,
      message: '',
      encryptedMessage: '',
      decryptedMessage: '',
      messageToSign: '',
      signature: '',
      isSignatureValid: null,
      certificateDetails: null,
    };
  },
  methods: {
    generateRSAKeyPair() {
      const rsaKeyPair = new RSAKey();
      rsaKeyPair.generate(2048, '10001');
      const privateKey = rsaKeyPair.getPrivateBaseKeyB64();
      const publicKey = rsaKeyPair.getPublicBaseKeyB64();

      this.rsaKeyPair = { privateKey, publicKey };
    },
    encryptMessage() {
      if (!this.rsaKeyPair || !this.message) return;
      const publicKey = new RSAKey();
      publicKey.setPublic(this.rsaKeyPair.publicKey);
      this.encryptedMessage = KJUR.crypto.Cipher.encrypt(this.message, publicKey);
    },
    decryptMessage() {
      if (!this.rsaKeyPair || !this.encryptedMessage) return;
      const privateKey = new RSAKey();
      privateKey.setPrivate(this.rsaKeyPair.privateKey);
      this.decryptedMessage = KJUR.crypto.Cipher.decrypt(this.encryptedMessage, privateKey);
    },
    signMessage() {
      if (!this.rsaKeyPair || !this.messageToSign) return;
      const privateKey = this.rsaKeyPair.privateKey;
      const sig = new KJUR.crypto.Signature({ alg: 'SHA256withRSA' });
      sig.init(privateKey);
      sig.updateString(this.messageToSign);
      this.signature = sig.sign();
    },
    verifySignature() {
      if (!this.rsaKeyPair || !this.messageToSign || !this.signature) return;
      const publicKey = this.rsaKeyPair.publicKey;
      const sig = new KJUR.crypto.Signature({ alg: 'SHA256withRSA' });
      sig.init(publicKey);
      sig.updateString(this.messageToSign);
      this.isSignatureValid = sig.verify(this.signature);
    },
    handleCertificateUpload(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const certPEM = e.target.result;
        const x509 = new X509();
        x509.readCertPEM(certPEM);
        this.certificateDetails = {
          issuer: x509.getIssuerString(),
          subject: x509.getSubjectString(),
          validFrom: x509.getNotBefore(),
          validTo: x509.getNotAfter(),
          publicKey: x509.getPublicKeyHex(),
        };
      };
      reader.readAsText(file);
    },
  },
};
</script>

<style scoped>
.crypto-panel {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}
.section {
  margin-bottom: 20px;
}
textarea {
  width: 100%;
  margin-top: 10px;
}
button {
  margin-top: 10px;
}
</style>